import { GET, POST } from '@/api/request';
import {
    GET_USER_INFO,
    LOCATION_TO_CITY,
    WX_SUBSCRIPTION_LOGIN,
    WX_SUBSCRIPTION_BIND,
    GET_INIT_CONFIG,
    GET_CITY_ID,
    GET_WX_CONFIG,
} from '@/api';
import {
    QZ_ABBREVIATION,
    QZ_ROUTER_PARAMS,
    QZ_CURRENT_SCENE,
    LOCATION_CURRENT,
    LOCATION_SET,
    GET_LOCATION_TIME,
    QZ_KF_CONFIG,
    QZ_PLATFORM_ID,
} from 'constant';
import loginModel from '@/components/loginModel';
import { PAGE_SET_CITY, PAGE_CUSTOMER_SERVICE, PAGE_ROOT } from 'page';
import store from '@/store';
import router from '@/router';
import { Indicator } from 'mint-ui';
let positionCallback = null;
let locationEnd = true;
let debounceTime = {};
let throttleTime = {};
let throttlePrevious = 0;

function positionSuccess({ callback = null, data = null } = {}) {
    if (locationEnd) {
        return;
    }
    locationEnd = true;
    console.log('util', '定位成功');
    let lat = data.position.lat;
    let lon = data.position.lng;
    util.post(LOCATION_TO_CITY, {
        lat,
        lon,
    }).then(res => {
        if (res.success) {
            store.commit('setHasLocation', true);
            store.commit('setCurrentLocation', {
                lat,
                lon,
                city: {
                    id: res.data.id,
                    name: res.data.name,
                },
            });
            if (callback) {
                callback({
                    success: true,
                    data: {
                        lat,
                        lon,
                        city: {
                            id: res.data.id,
                            name: res.data.name,
                        },
                    },
                });
            }
        }
    });
}

function positionError({ callback = null, error = {} } = {}) {
    if (locationEnd) {
        return;
    }
    locationEnd = true;
    console.error('util', '定位失败');
    switch (error.code) {
        case error.PERMISSION_DENIED:
            console.error('util', '用户拒绝请求地理定位');
            break;
        case error.POSITION_UNAVAILABLE:
            console.error('util', '位置信息是不可用');
            break;
        case error.TIMEOUT:
            console.error('util', '请求获取用户位置超时');
            break;
        case error.UNKNOWN_ERROR:
            console.error('util', '定位系统失效');
            break;
        default:
            console.error('util', '未知问题');
    }
    if (
        router.currentRoute.path != PAGE_SET_CITY &&
        !store.state.location.selectLocation.city.id &&
        !store.state.location.currentLocation.city.id
    ) {
        router.replace({ path: `${PAGE_SET_CITY}?type=1&source=${window.location.href}` });
    }
    if (callback) {
        callback({ success: false });
    }
}

const util = {
    /**
     * 生成guid
     */
    guid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (Math.random() * 16) | 0,
                v = c == 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    },
    /**
     * 获取当前渠道信息
     */
    getPlatformInfo(platformId = '') {
        platformId = platformId || localStorage.getItem(QZ_PLATFORM_ID);
        return GET(`${GET_INIT_CONFIG}/${platformId}`).then(res => {
            if (res.success) {
                let allowEatType = res.data.enableKfcTakeout;
                store.commit('setConfig', res.data);
                store.commit('setAllowEatType', allowEatType);
                // 微信支付白名单
                store.commit('setWechatPayAllowDomains', res.data.wechatPayAllowDomains);
                store.commit('setWechatPayRedirectDomain', res.data.wechatPayRedirectDomain);
                if (store.getters.getExposeMethod.notWechatShare) {
                    document.addEventListener('WeixinJSBridgeReady', function onBridgeReady() {
                        WeixinJSBridge.call('hideOptionMenu');
                    });
                }
            } else {
                document.getElementById('app').remove();
            }
            return res;
        });
    },
    /**
     * 获取用户信息并同步数据
     */
    getUserInfo() {
        return POST(GET_USER_INFO).then(res => {
            if (res.success) {
                // 当无openId的时候去验证一下是否需要绑定
                if (!res.data.wxOpenId) {
                    util.wxMpLogin();
                }
                store.commit('setUserInfo', res.data);
                return res;
            }
            return {
                success: false,
            };
        });
    },
    /**
     * 获取当前定位
     */
    getLocation({ callback = null, presetLocation = null } = {}) {
        locationEnd = false;
        positionCallback = callback;
        let localStorageCurrent = localStorage.getItem(LOCATION_CURRENT);
        let localStorageSet = localStorage.getItem(LOCATION_SET);
        var _currentLocation = {};
        var _setLocation = {};
        var promiseList = [];
        if (localStorageCurrent) {
            _currentLocation = JSON.parse(localStorageCurrent);
            if (
                !_currentLocation.city.hasOwnProperty('id') &&
                _currentLocation.city.hasOwnProperty('code') &&
                _currentLocation.city.code
            ) {
                promiseList.push({
                    name: 'currentLocation',
                    request: util.post(GET_CITY_ID, {
                        cityCode: _currentLocation.city.code,
                    }),
                });
            }
        }
        if (localStorageSet) {
            _setLocation = JSON.parse(localStorageSet);
            if (
                !_setLocation.city.hasOwnProperty('id') &&
                _setLocation.city.hasOwnProperty('code') &&
                _setLocation.city.code
            ) {
                promiseList.push({
                    name: 'setLocation',
                    request: util.post(GET_CITY_ID, {
                        cityCode: _setLocation.city.code,
                    }),
                });
            }
        }

        if (promiseList.length == 0) {
            next(localStorageCurrent, localStorageSet, presetLocation);
        } else {
            Promise.all(
                promiseList.map(v => {
                    if (typeof v.request == 'object') {
                        return v.request;
                    } else {
                        return v.request();
                    }
                })
            ).then(([...promiseResList]) => {
                for (let i = 0; i < promiseList.length; i++) {
                    const promiseItem = promiseList[i];
                    const promiseResItem = promiseResList[i];
                    switch (promiseItem.name) {
                        case 'currentLocation':
                            if (promiseResItem.success) {
                                _currentLocation.city.id = promiseResItem.data.id;
                                localStorageCurrent = JSON.stringify(_currentLocation);
                            }
                            break;
                        case 'setLocation':
                            if (promiseResItem.success) {
                                _setLocation.city.id = promiseResItem.data.id;
                                _setLocation.lon = promiseResItem.data.longitude;
                                _setLocation.lat = promiseResItem.data.latitude;
                                localStorageSet = JSON.stringify(_setLocation);
                            }
                            break;
                        default:
                            break;
                    }
                }
                next(localStorageCurrent, localStorageSet, presetLocation);
            });
        }

        function next(localStorageCurrent, localStorageSet, presetLocation) {
            if (!store.state.location.currentLocation.city.id && localStorageCurrent) {
                let _currentLocation = JSON.parse(localStorageCurrent);
                store.commit('setCurrentLocation', _currentLocation);
                store.commit('setHasLocation', true);
            } else {
                store.commit('setHasLocation', false);
            }
            if (!store.state.location.selectLocation.city.id && localStorageSet) {
                store.commit('setSelectLocation', JSON.parse(localStorageSet));
            }
            util.setCurrentScene().then(currentRes => {
                if (currentRes.success) {
                    store.dispatch('wechatPay').then(wechatPayRes => {
                        const writeDomains = wechatPayRes.allowDomains;
                        if (
                            store.getters.isWechatBrowser &&
                            writeDomains.indexOf(window.location.hostname) > -1
                        ) {
                            util.post(GET_WX_CONFIG, {
                                url: store.state.common.wxConfigHref,
                            }).then(res => {
                                if (res.success) {
                                    let config = Object.assign(res.data, {
                                        debug: false,
                                        jsApiList: [
                                            'chooseWXPay',
                                            'getLocation',
                                            'updateAppMessageShareData',
                                            'updateTimelineShareData',
                                            'onMenuShareTimeline',
                                            'onMenuShareAppMessage',
                                        ],
                                    });
                                    wx.config(config);
                                    wx.ready(function () {
                                        console.log('util', 'wx.ready');
                                        wx.getLocation({
                                            type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                                            success: function (res) {
                                                console.log('util', 'getLocation', 'success', res);
                                                if (res.errMsg.indexOf('ok') > -1) {
                                                    positionSuccess({
                                                        callback,
                                                        data: {
                                                            position: {
                                                                lat: res.latitude,
                                                                lng: res.longitude,
                                                            },
                                                        },
                                                    });
                                                }
                                            },
                                            cancel: function (res) {
                                                console.error('util', 'getLocation', 'cancel', res);
                                                defaultSetCurrentScene({
                                                    callback,
                                                    presetLocation,
                                                });
                                            },
                                            fail: function (res) {
                                                console.error('util', 'getLocation', 'fail', res);
                                                defaultSetCurrentScene({
                                                    callback,
                                                    presetLocation,
                                                });
                                            },
                                        });
                                    });
                                    wx.error(function (res) {
                                        console.error('util', '注册失败', res);
                                        defaultSetCurrentScene({ callback, presetLocation });
                                    });
                                }
                            });
                        } else {
                            defaultSetCurrentScene({ callback, presetLocation });
                        }
                    });
                }
            });
        }
        function defaultSetCurrentScene({ callback = null, presetLocation = null } = {}) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        positionSuccess({
                            callback,
                            data: {
                                position: {
                                    lat: position.coords.latitude,
                                    lng: position.coords.longitude,
                                },
                            },
                        });
                    },
                    error => {
                        if (presetLocation) {
                            positionSuccess({
                                callback,
                                data: {
                                    position: {
                                        lat: presetLocation.lat,
                                        lng: presetLocation.lng,
                                    },
                                },
                            });
                        } else {
                            positionError({ callback, error });
                        }
                    },
                    {
                        enableHighAccuracy: false,
                        maximumAge: 0, // 设置定位缓存过期的时间（毫秒，0为禁用缓存）
                        timeout: GET_LOCATION_TIME, // 设置获取定位信息的时常 （超时触发ErrorCallback）
                    }
                );
            } else if (presetLocation) {
                positionSuccess({
                    callback,
                    data: {
                        position: {
                            lat: presetLocation.lat,
                            lng: presetLocation.lng,
                        },
                    },
                });
            } else {
                console.error('util', '浏览器不支持地理定位。');
                positionError({ callback });
            }
            // if (navigator.geolocation) {
            //     AMap.plugin('AMap.Geolocation', function () {
            //         var geolocation = new AMap.Geolocation({
            //             // 是否使用高精度定位，默认：true
            //             enableHighAccuracy: true,
            //             // 设置定位超时时间，默认：无穷大
            //             timeout: GET_LOCATION_TIME,
            //         });
            //         geolocation.getCurrentPosition(function (status, result) {
            //             if (status == 'complete') {
            //                 positionSuccess({
            //                     callback,
            //                     data: result,
            //                 });
            //             } else if (presetLocation) {
            //                 positionSuccess({
            //                     callback,
            //                     data: {
            //                         position: {
            //                             lat: presetLocation.lat,
            //                             lng: presetLocation.lng,
            //                         },
            //                     },
            //                 });
            //             } else {
            //                 // getLngLatLocation({ callback });
            //                 positionError({ callback, data: result });
            //             }
            //         });
            //     });
            // } else if (presetLocation) {
            //     positionSuccess({
            //         callback,
            //         data: {
            //             position: {
            //                 lat: presetLocation.lat,
            //                 lng: presetLocation.lng,
            //             },
            //         },
            //     });
            // } else {
            //     console.error('util', '浏览器不支持地理定位。');
            //     positionError({ callback });
            // }
        }
        function getLngLatLocation({ callback = null } = {}) {
            AMap.plugin('AMap.CitySearch', function () {
                var citySearch = new AMap.CitySearch();
                citySearch.getLocalCity(function (status, result) {
                    if (status === 'complete' && result.info === 'OK') {
                        var lngLat = result.rectangle.split(';')[0].split(',');
                        positionSuccess({
                            callback,
                            data: {
                                position: {
                                    lat: lngLat[1],
                                    lng: lngLat[0],
                                },
                            },
                        });
                    } else {
                        positionError({ callback, data: result });
                    }
                });
            });
        }
    },
    /**
     * 获取客服链接
     */
    getCustomerService(orderNo = '') {
        let defaultDomain = '//kf.qianzhu8.com';
        if (window.location.hostname.indexOf('qz-m') > -1) {
            defaultDomain = `//${window.location.hostname.replace('qz-m', 'qz-kf')}`;
        }
        return new Promise((resolve, reject) => {
            if (store.getters.getExposeMethod.kfDomain && store.getters.getExposeMethod.kfDomain.indexOf('tel:') > -1) {
                resolve({
                    success: true,
                    url: store.getters.getExposeMethod.kfDomain,
                });
            } else if (!store.state.user.userInfo.id) {
                this.getUserInfo().then(res => {
                    if (res.success) {
                        resolve({
                            success: true,
                            url:
                                (store.getters.getExposeMethod.kfDomain || `${defaultDomain}`) +
                                `/api/mobileweb/home?source=${QZ_KF_CONFIG.SOURCE}&channel_id=${
                                    QZ_KF_CONFIG.CHANNEL_ID
                                }&channel_key=${QZ_KF_CONFIG.CHANNEL_KEY}&wechatapp_id=${
                                    QZ_KF_CONFIG.WECHATAPP_ID
                                }&key=${QZ_KF_CONFIG.KEY}&orderNo=${orderNo}&openid=${
                                    res.data.id
                                }&nickName=${res.data.nickname || ''}&note=${
                                    store.state.common.platformId || ''
                                }&avatarUrl=${res.data.headImgUrl}`,
                        });
                    } else {
                        resolve({
                            success: false,
                            url:
                                (store.getters.getExposeMethod.kfDomain || `${defaultDomain}`) +
                                `/api/mobileweb/home?source=${QZ_KF_CONFIG.SOURCE}&channel_id=${
                                    QZ_KF_CONFIG.CHANNEL_ID
                                }&channel_key=${QZ_KF_CONFIG.CHANNEL_KEY}&wechatapp_id=${
                                    QZ_KF_CONFIG.WECHATAPP_ID
                                }&key=${
                                    QZ_KF_CONFIG.KEY
                                }&orderNo=${orderNo}&openid=${new Date().getTime()}&nickName=游客用户&note=${
                                    store.state.common.platformId || ''
                                }`,
                        });
                    }
                });
            } else {
                resolve({
                    success: true,
                    url:
                        (store.getters.getExposeMethod.kfDomain || `${defaultDomain}`) +
                        `/api/mobileweb/home?source=${QZ_KF_CONFIG.SOURCE}&channel_id=${
                            QZ_KF_CONFIG.CHANNEL_ID
                        }&channel_key=${QZ_KF_CONFIG.CHANNEL_KEY}&wechatapp_id=${
                            QZ_KF_CONFIG.WECHATAPP_ID
                        }&key=${QZ_KF_CONFIG.KEY}&orderNo=${orderNo}&openid=${
                            store.state.user.userInfo.id
                        }&nickName=${store.state.user.userInfo.nickname || ''}&note=${
                            store.state.common.platformId || ''
                        }&avatarUrl=${store.state.user.userInfo.headImgUrl}`,
                });
            }
        });
    },
    /**
     * 页面跳转
     * @param  {...any} args 入参
     */
    pageJump(...args) {
        let type = 0;
        let path = '/';
        let name = '';
        let query = null;
        let params = null;
        let config = {};
        if (typeof args[0] === 'string') {
            type = 0;
            path = args[0];
            query = args[1] || {};
            params = args[2] || {};
            config = args[3] || {};
        } else {
            type = 1;
            path = args[0].path;
            query = args[0].query || {};
            params = args[0].params || {};
            for (let k in args[0]) {
                if (k != 'path' && k != 'query' && k != 'params') {
                    config[k] = args[0][k];
                }
            }
            config = args[0].config || {};
        }
        if (PAGE_CUSTOMER_SERVICE == path && !store.state.user.token) {
            return loginModel.open();
        }
        if (params) {
            for (let i = 0; i < router.options.routes.length; i++) {
                const item = router.options.routes[i];
                if (item.path === path) {
                    name = item.name;
                    break;
                } else if (
                    item.children &&
                    item.children.length &&
                    this.analysisMultistageRouter(item.children, path).success
                ) {
                    name = this.analysisMultistageRouter(item.children, path).name;
                    break;
                }
            }
            localStorage.setItem(QZ_ROUTER_PARAMS, JSON.stringify(params));
        }
        if (query && query.titleName) {
            // this.setTitleName(query.titleName);
            store.commit('setTitleName', name);
        } else {
            store.commit('setTitleName', '');
        }
        if (query && !query.platformId) {
            query.platformId = store.state.common.platformId;
        }
        if (query && !query.subPlatformId && store.state.common.subPlatformId) {
            query.subPlatformId = store.state.common.subPlatformId;
        }
        if (query && !query.merchId && store.state.common.merchId) {
            query.merchId = store.state.common.merchId;
        }
        if (query && !query.promoterId && store.state.common.promoterId) {
            query.promoterId = store.state.common.promoterId;
        }
        console.log(config.jumpType);
        if (config.jumpType === 1) {
            router.replace({
                path,
                name,
                query,
                params,
            });
        } else {
            router.push({
                path,
                name,
                query,
                params,
            });
        }
    },
    /**
     * 清楚截流防抖时间
     */
    clearMyTime() {
        for (let k in debounceTime) {
            clearTimeout(debounceTime[k]);
            debounceTime[k] = null;
        }
        for (let k in throttleTime) {
            clearTimeout(throttleTime[k]);
            throttleTime[k] = null;
        }
    },
    /**
     * 解析子路由list
     * @param {Array} routeList 子路由的list
     * @param {String} path 跳转url名称
     */
    analysisMultistageRouter(routeList, path) {
        for (let i = 0; i < routeList.length; i++) {
            const item = routeList[i];
            if (item.path === path) {
                return {
                    success: true,
                    name: item.name,
                };
            } else if (
                item.children &&
                item.children.length &&
                this.analysisMultistageRouter(item.children, path).success
            ) {
                return this.analysisMultistageRouter(item.children, path).name;
            }
        }
        return {
            success: false,
        };
    },
    /**
     * 后退
     * @param {Int} n 回退页面层数
     */
    pageBack(n = 1) {
        store.commit('clearTitleName');
        router.go(-n);
    },
    /**
     * get 请求
     * @param  {...any} args 入参
     */
    get(...args) {
        return GET(...args);
    },
    /**
     * post 请求
     * @param  {...any} args 入参
     */
    post(...args) {
        return POST(...args);
    },
    /**
     * 获取上一个页面携带的参数
     * @param {String}} name 获取参数名称
     */
    getQueryString(name = '') {
        var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
        var r = window.location.search.substr(1).match(reg);
        if (r != null) return decodeURIComponent(r[2]);
        let lsParams = JSON.parse(localStorage.getItem(QZ_ROUTER_PARAMS));
        for (let k in lsParams) if (k == name) return lsParams[k];
        return null;
    },
    /**
     * 设置当前场景值
     */
    setCurrentScene() {
        return new Promise((resolve, reject) => {
            if (store.state.common.sceneType == 1) {
                return resolve({
                    success: true,
                    currentScene: store.state.common.currentScene,
                });
            } else if (navigator && navigator.userAgent) {
                var ua = navigator.userAgent.toLowerCase();
                if (ua.match(/MicroMessenger/i) == 'micromessenger') {
                    //ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
                    wx.miniProgram.getEnv(res => {
                        if (res.miniprogram) {
                            store.commit('setCurrentScene', QZ_CURRENT_SCENE.WX_MIN);
                            resolve({ success: true, currentScene: QZ_CURRENT_SCENE.WX_MIN });
                        } else {
                            store.commit('setCurrentScene', QZ_CURRENT_SCENE.WX_BROWSER);
                            resolve({ success: true, currentScene: QZ_CURRENT_SCENE.WX_BROWSER });
                        }
                    });
                } else if (ua.match(/toutiaomicroapp/i) == 'toutiaomicroapp') {
                    // 防止头条 Jssdk 与 Rn 语法冲突
                    require('@/lib/toutiao/toutiao.tmajssdk.jssdk-1.0.1.js');
                    store.commit('setCurrentScene', QZ_CURRENT_SCENE.TT_MIN);
                    resolve({ success: true, currentScene: QZ_CURRENT_SCENE.TT_MIN });
                } else if (ua.match(/AlipayClient/i) == 'alipayclient') {
                    util.dynamicLoadJs('https://appx/web-view.min.js');
                    // 支付宝环境
                    if (ua.match(/MiniProgram/i) == 'miniprogram') {
                        // 支付宝小程序
                        store.commit('setCurrentScene', QZ_CURRENT_SCENE.ALIPAY_MIN);
                        resolve({ success: true, currentScene: QZ_CURRENT_SCENE.ALIPAY_MIN });
                    } else {
                        // 支付宝H5
                        store.commit('setCurrentScene', QZ_CURRENT_SCENE.ALIPAY_BROWSER);
                        resolve({ success: true, currentScene: QZ_CURRENT_SCENE.ALIPAY_BROWSER });
                    }
                } else if (ua.match(/swan/i) == 'swan') {
                    // 百度小程序环境
                    require('@/lib/baidu/swan-2.0.22.js');
                    store.commit('setCurrentScene', QZ_CURRENT_SCENE.BD_MIN);
                    resolve({ success: true, currentScene: QZ_CURRENT_SCENE.BD_MIN });
                } else {
                    store.commit('setCurrentScene', QZ_CURRENT_SCENE.APP);
                    resolve({ success: true, currentScene: QZ_CURRENT_SCENE.APP });
                }
            } else {
                store.commit('setCurrentScene', QZ_CURRENT_SCENE.APP);
                resolve({ success: true, currentScene: QZ_CURRENT_SCENE.APP });
            }
        });
    },
    /**
     * 设置标题名称
     * @param {String} name 标题名称
     */
    setTitleName(name = '') {
        store.commit('setTitleName', name);
        this.setDocumentTitle(name);
    },
    /**
     *
     * @param {String} name 标题名称
     */
    setDocumentTitle(name = '') {
        document.title = QZ_ABBREVIATION + (name ? ' - ' + name : '');
    },
    /**
     * 校验是否在android中
     */
    isAndroid() {
        var u = navigator.userAgent;
        return u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
    },
    /**
     * 微信公众号授权登录
     */
    wxMpLogin() {
        // 获取环境信息
        util.setCurrentScene().then(sceneRes => {
            if (sceneRes.success) {
                // 当在微信环境中触发
                if (store.getters.isWechatBrowser) {
                    // 有渠道 且 有token的时候去绑定授权
                    if (store.state.common.platformId && store.state.user.token) {
                        util.get(WX_SUBSCRIPTION_BIND).then(loginRes => {
                            if (loginRes.success) {
                                window.location.href = loginRes.data.bindUrl;
                            }
                        });
                    } else {
                        util.get(WX_SUBSCRIPTION_LOGIN).then(loginRes => {
                            if (loginRes.success) {
                                window.location.href = loginRes.data.authorizationUrl;
                            }
                        });
                    }
                }
            } else {
            }
        });
    },
    /**
     * 数组去重
     * @param {Array} list 目标数组
     * @param {String} name 字段名称
     */
    distinct(list = [], name = '') {
        if (typeof name == 'string') {
            if (name) {
                for (let i = 0; i < list.length; i++) {
                    for (let j = i + 1; j < list.length; j++) {
                        if (list[i][name] === list[j][name]) {
                            list.splice(j, 1);
                            j--;
                        }
                    }
                }
            } else {
                for (let i = 0; i < list.length; i++) {
                    for (let j = i + 1; j < list.length; j++) {
                        if (list[i] === list[j]) {
                            list.splice(j, 1);
                            j--;
                        }
                    }
                }
            }
            return list;
        } else {
            for (let i = 0; i < list.length; i++) {
                let iItem = list[i];
                for (let j = i + 1; j < list.length; j++) {
                    let jItem = list[j];
                    let isOk = false;
                    for (let k = 0; k < name.length; k++) {
                        const kItem = name[k];
                        console.log(iItem[kItem], jItem[kItem]);
                        if (iItem[kItem] === jItem[kItem] && iItem[kItem] && jItem[kItem]) {
                            isOk = true;
                            break;
                        }
                    }
                    if (isOk) {
                        list.splice(j, 1);
                        j--;
                    }
                }
            }
            return list;
        }
    },
    /**
     * 浏览器锁住滚动条
     */
    lockScreen() {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'fixed';
        document.body.style.top = -scrollTop + 'px';
    },
    /**
     * 解开浏览器滚动条
     */
    unlockScreen({ _top = null } = {}) {
        let top = _top == null ? -parseInt(document.body.style.top) : _top;
        document.body.style.overflow = '';
        document.body.style.position = '';
        document.body.style.top = '';
        document.documentElement.scrollTop = top;
        document.body.scrollTop = top;
    },
    /**
     * 加载框
     * @param {String} text 文本
     */
    loading(text = '加载中...') {
        Indicator.open(text);
    },
    /**
     * 关闭加载框
     */
    unloading() {
        Indicator.close();
    },
    /**
     * 是否safari
     */
    isSafari() {
        var isSafari = false;
        var ua = navigator.userAgent.toLowerCase();
        //判断是不是在iPhone的Safair浏览器打开的本页面
        if (
            ua.indexOf('applewebkit') > -1 &&
            ua.indexOf('mobile') > -1 &&
            ua.indexOf('safari') > -1 &&
            ua.indexOf('linux') === -1 &&
            ua.indexOf('android') === -1 &&
            ua.indexOf('chrome') === -1 &&
            ua.indexOf('ios') === -1 &&
            ua.indexOf('browser') === -1
        ) {
            isSafari = true;
        }
        return isSafari;
    },
    /**
     * 关闭当前页面 使用页面回退的方式
     */
    closeWindow() {
        for (let i = 100; i > 0; i--) {
            window.history.go(-i);
        }
    },
    /**
     * 函数防抖
     * @param {Function}} func 函数
     * @param {Int}} wait 延迟执行毫秒数
     * @param {Bool} immediate 是否立即执行
     */
    debounce(func, wait = 1000, immediate = true) {
        return function () {
            let context = this;
            let args = arguments;
            if (debounceTime[func.name]) {
                clearTimeout(debounceTime[func.name]);
            }
            if (immediate) {
                var callNow = !debounceTime[func.name];
                debounceTime[func.name] = setTimeout(() => {
                    delete debounceTime[func.name];
                }, wait);
                if (callNow) func.apply(context, args);
            } else {
                debounceTime[func.name] = setTimeout(function () {
                    func.apply(context, args);
                }, wait);
            }
        };
    },
    /**
     * 函数节流
     * @param {Function} func 函数
     * @param {Int} wait 延迟执行毫秒数
     * @param {Int} type 版本 1. 时间戳 2.定时器
     */
    throttle(func, wait = 1000, type = 1) {
        return function () {
            let context = this;
            let args = arguments;
            if (type === 1) {
                let now = Date.now();
                if (now - throttlePrevious > wait) {
                    func.apply(context, args);
                    throttlePrevious = now;
                }
            } else if (type === 2) {
                if (!throttleTime[func.name]) {
                    throttleTime[func.name] = setTimeout(() => {
                        delete throttleTime[func.name];
                        func.apply(context, args);
                    }, wait);
                }
            }
        };
    },
    eventStatistics({ title, message, data } = {}) {
        try {
            if (store.getters.getExposeMethod.ShowStatistics) {
                TDAPP.onEvent(`${store.state.common.platformId}-${title}`, message, data);
            }
            TDAPP.onEvent(`All-${title}`, message, data);
        } catch (error) {
            console.error('util', 'eventStatistics', error);
        }
    },
    /**
     * 动态引入js
     * @param {String} url js链接
     * @param {Function} callback 回掉方法
     */
    dynamicLoadJs(url, callback) {
        var head = document.getElementsByTagName('head')[0];
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        if (typeof callback == 'function') {
            script.onload = script.onreadystatechange = function () {
                if (
                    !this.readyState ||
                    this.readyState === 'loaded' ||
                    this.readyState === 'complete'
                ) {
                    callback();
                    script.onload = script.onreadystatechange = null;
                }
            };
        }
        head.appendChild(script);
    },
    getTempData(page, name) {
        let tempData = JSON.parse(window.localStorage.getItem('QZ_TEMP_DATA') || '{}');
        return tempData[page]?.[name];
    },
    setTempData(page, name, value) {
        let tempData = JSON.parse(window.localStorage.getItem('QZ_TEMP_DATA') || '{}');
        !tempData[page] && (tempData[page] = {});
        tempData[page][name] = value;
        window.localStorage.setItem('QZ_TEMP_DATA', JSON.stringify(tempData));
    },
    clearTempData(page) {
        let tempData = JSON.parse(window.localStorage.getItem('QZ_TEMP_DATA') || '{}');
        delete tempData[page];
        window.localStorage.setItem('QZ_TEMP_DATA', JSON.stringify(tempData));
    },
};

export default util;
